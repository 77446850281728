import klogo from "./images/kaligent-logo.jpg";
import jamesLowey from "./images/james-300x366.jpg";
import stuShannon from "./images/stu-shannon-300x366.jpg";
import apurvaPatel from "./images/apurva-patel-300x366.png";
import beatrizCampos from "./images/beatriz-campos-300x366.png";

import Vision from "./images/Vision.png";
import Mission from "./images/Mission.png";
import partners from "./images/Partners-new.jpg";
//import backgroundLeft from "./images/left-lines.png";

import Service from "./Service.js";
import Member from "./Member.js";
import CheckBoxCard from "./CheckBoxCard.js";
import IconBoxCard from "./IconBoxCard.js";
//import Faq from "./FAQ.js";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "bootstrap-icons/font/bootstrap-icons.css";
import { TbMedicalCross, TbDeviceDesktopAnalytics } from "react-icons/tb";
import { GiMicroscope, GiTestTubes, GiDna1 } from "react-icons/gi";
import { MdOutlineAnalytics } from "react-icons/md";
import { RiFoldersFill } from "react-icons/ri";

import "./App.css";

function App() {  
 

  return (
    <div className="App">
      <div className="wrapper">
        <div className="container content">
          <div className="headline-center">
            <img className="img-responsive" src={klogo} alt="Kaligent Logo" />
          </div>
        </div>
        <div className="slider-inner">
          <div id="da-slider" className="da-slider">
            <div className="da-slide da-slide-current">
              <h1>
                <i>Helping healthcare and life science customers</i>
                <br />
                <i>derive insights that impact patient lives.</i>
              </h1>
            </div>
          </div>
        </div>
        <div className="purchase">
          <div className="container">
            <div className="row">
              <div className="col-md-12 animated fadeInLeft">
                <h2 className="customHeader">What We Do</h2>
                <p>
                  Kaligent&trade; provides software products and services
                  designed to help healthcare and life science customers derive
                  data-driven insights that impact patient care and outcomes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="purchase">
          <div className="container">
            <div className="row">
              <div className="col-md-12 animated fadeInLeft">
                <h2 className="customHeader">About Kaligent</h2>
                <p>
                  As a wholly owned subsidiary of TGen, an institute with a
                  legacy rooted in genomic research, translational discovery,
                  and high performance computing, Kaligent is uniquely
                  positioned to commercialize its IP for pharma, payers, and
                  providers. Our work seeks to redefine the patient care
                  experience and advocate for greater data stewardship, security
                  and management.
                </p>
                <br />
                <p>
                  Partnered with key technology providers such as Dell, Intel
                  and others, Kaligent seeks to democratize data and insights by
                  providing flexible consumption models for agile,
                  cost-effective solution deployments driving innovation that
                  benefits all of humanity.
                </p>
                <br />
                <p>
                  We are currently looking to collaborate and engage with
                  forward looking institutions interested in how we are
                  transforming the industry.
                </p>
              </div>
            </div>
          </div>
        </div>       
      
        <div className="purchase">
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-4 animated fadeInLeft">
                <div className="card">
                  <img
                    src={Vision}
                    className="card-img-top img-responsive"
                    alt="Vision"
                  />
                  <div className="card-body  shadow bg-body-tertiary rounded">
                    <p className="card-text text-center">
                    To be the leader in applying molecular insights to impact outcomes for everyone.
                    </p>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 animated fadeInRight">
                <div className="card">
                  <img
                    src={Mission}
                    className="card-img-top img-responsive"
                    alt="Mission"
                  />
                  <div className="card-body shadow bg-body-tertiary rounded">
                    <p className="card-text text-center">
                      To deliver technology driven capabilities that help
                      healthcare and life science customers transform data into
                      intelligence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="container content">
            <div className="headline-center headerFontColorDark margin-bottom-60">
              <h2>KALIGENT OFFERINGS</h2>
              <p></p>
            </div>
            <div className="row margin-bottom-30">
              <div className="col-sm-12 sm-margin-bottom-20">      
                <div className="row margin-bottom-30">
                  <div className="col-sm-12">
                    <div className="service-block-v8 shadow p-3 mb-5 bg-body-tertiary rounded">
                      <div className="service-block-desc text-center margin-bottom-30">
                        <h4 className="customHeader">KaligentOne&trade;</h4>
                        <p>
                          Integrate data, applications, and analytics using
                          KaligentOne&trade; our secure platform to deliver
                          molecular insights.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-block-v8 shadow p-3 mb-5 bg-body-tertiary rounded">
                  <div className="service-block-desc text-center margin-bottom-30">
                    <h4 className="customHeader">Solution Portfolio</h4>
                    <p>
                      From laboratory informatics to automation, our in-depth
                      knowledge of laboratory workflows and expertise around
                      building solutions maximize accuracy and throughput from
                      sample to insight.
                    </p>
                  </div>
                  <Tabs defaultFocus={false} defaultIndex={0}>
                    <TabList>
                      <Tab>
                        <TbMedicalCross color="90c745" size={53} />{" "}
                        OneView&trade;
                      </Tab>
                      <Tab>
                        <GiMicroscope color="90c745" size={53} />{" "}
                        KALIdoscope&trade;
                      </Tab>
                      <Tab>
                        <MdOutlineAnalytics color="90c745" size={58} />{" "}
                        CohortSelect&trade;
                      </Tab>
                      <Tab>
                        <GiTestTubes color="90c745" size={48} /> OneLIMS&trade;
                      </Tab>
                      <Tab>
                        {" "}
                        <GiDna1 color="90c745" size={48} /> MolecularOne&trade;
                      </Tab>
                      <Tab>
                        <TbDeviceDesktopAnalytics color="90c745" size={53} />{" "}
                        Kaliseq&trade;
                      </Tab>
                      <Tab>
                        <RiFoldersFill color="90c745" size={53} />{" "}
                        COMPLIAssist&trade;
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <div className="service-block-v8 shadow p-3 mb-5 bg-body-tertiary rounded">
                        <div className="service-block-desc tabBox">
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <p className="text-center">
                                Portal to aid Clinicians with:{" "}
                              </p>
                              <ul>
                                <li className="bi bi-plus-circle-fill themeColor">
                                  {" "}
                                  <span className="themeColorDark">
                                    Digital ordering
                                  </span>
                                </li>
                                <li className="bi bi-plus-circle-fill themeColor">
                                  {" "}
                                  <span className="themeColorDark">
                                    Real time Status tracking
                                  </span>
                                </li>
                                <li className="bi bi-plus-circle-fill themeColor">
                                  {" "}
                                  <span className="themeColorDark">
                                    Exception notifications
                                  </span>
                                </li>
                                <li className="bi bi-plus-circle-fill themeColor">
                                  {" "}
                                  <span className="themeColorDark">
                                    Results delivery
                                  </span>
                                </li>
                                <li className="bi bi-plus-circle-fill themeColor">
                                  {" "}
                                  <span className="themeColorDark">
                                    Data mining
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-4"></div>
                          </div>
                        </div>
                      </div>                      
                    </TabPanel>
                    <TabPanel>
                      <Service title="" desc="Integrating clinical, molecular, and pathology data to better manage patient care, outcomes, and clinical decisions." />
                    </TabPanel>
                    <TabPanel>
                      <Service title="" desc="Efficiently identify the right cohorts for clinical trials while considering patient outcomes based on similar  individualized molecular profiles to derive insights that optimize treatment." />
                    </TabPanel>
                    <TabPanel>
                      <Service title="" desc="Orchestrates lab operations from digital ordering to results delivery. <br/> Seamlessly integrate instrumentation, bioinformatic pipelines, and reporting engines." />
                    </TabPanel>
                    <TabPanel>
                      <Service title="" desc="Automated reporting to support laboratory assays to ensure expedited turnaround times and drive compliance." />
                    </TabPanel>
                    <TabPanel>
                      <Service title="" desc="A secure bioinformatics pipeline that support various sequencing initiatives that’s configurable, and scalable." />
                    </TabPanel>
                    <TabPanel>
                    <div className="service-block-v8 shadow p-3 mb-5 bg-body-tertiary rounded">
                        <div className="service-block-desc tabBox">
                          <div className="row">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                              <p className="text-center">
                              Support regulatory compliance for:{" "}
                              </p>
                              <ul>
                                <li className="bi bi-award-fill themeColor">
                                  {" "}
                                  <span className="themeColorDark">
                                  21 CFR Part 11
                                  </span>
                                </li>
                                <li className="bi bi-award-fill themeColor">
                                  {" "}
                                  <span className="themeColorDark">
                                  HIPAA
                                  </span>
                                </li>
                                <li className="bi bi-award-fill themeColor">
                                  {" "}
                                  <span className="themeColorDark">
                                  CLIA
                                  </span>
                                </li>
                                <li className="bi bi-award-fill themeColor">
                                  {" "}
                                  <span className="themeColorDark">
                                  510 (k)/PMA
                                  </span>
                                </li>                                
                              </ul>
                            </div>
                            <div className="col-md-4"></div>
                          </div>
                        </div>
                      </div>                         
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="service-block-v8 shadow p-3 mb-5 bg-body-tertiary rounded">
                  <div className="service-block-desc text-center margin-bottom-30">
                    <h4 className="customHeader">Services</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <CheckBoxCard desc="Laboratory Information Management System Professional Services"></CheckBoxCard>
                      <CheckBoxCard desc="Process Automation & Instrument Integration Services"></CheckBoxCard>
                      <CheckBoxCard desc="Bioinformatics & Multiomics Interpretation Services"></CheckBoxCard>
                    </div>
                    <div className="col-md-6">
                      <CheckBoxCard desc="Precision Medicine Software Development & Integration Services"></CheckBoxCard>
                      <CheckBoxCard desc="High Performance Computing Advisory Services "></CheckBoxCard>
                      <CheckBoxCard desc="Regulatory Compliance Support "></CheckBoxCard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="serviceBGColor margin-bottom-30">
            <div className="container content">
              <div className="headline-center headerFontColor">
                <h2>MARKETS WE SERVE </h2>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-md-6">
                      <IconBoxCard
                        icon="bi bi-1-circle-fill"
                        desc="Molecular Testing Laboratories"
                      ></IconBoxCard>
                      <IconBoxCard
                        icon="bi bi-2-circle-fill"
                        desc="Community Healthcare Centers"
                      ></IconBoxCard>
                      <IconBoxCard
                        icon="bi bi-3-circle-fill"
                        desc="Oncology Practices"
                      ></IconBoxCard>
                    </div>
                    <div className="col-md-6">
                      <IconBoxCard
                        icon="bi bi-4-circle-fill"
                        desc="Genomic Research Organizations"
                      ></IconBoxCard>
                      <IconBoxCard
                        icon="bi bi-5-circle-fill"
                        desc="Pharma - Biotech"
                      ></IconBoxCard>
                      <IconBoxCard
                        icon="bi bi-6-circle-fill"
                        desc="Payors"
                      ></IconBoxCard>
                      {/* </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="purchase">
          <div className="container content">
            <div className="headline-center headerFontColorDark margin-bottom-30">
              <h2>CUSTOMERS &amp; PARTNERS</h2>
              <p></p>
            </div>
            <div className="row">
              <div className="col-md-12">               
                  <img src={partners} alt="Cusstomers and Partners" className="img-responsive" />
                </div>                
            </div>
          </div>
        </div>
        <div className="container content">
          <div className="headline-center headerFontColorDark margin-bottom-30">
            <h2>KALIGENT LEADERSHIP TEAM</h2>
            <p></p>
          </div>
          <div className="row headline-center">
            <div className="col-md-12">
              <div className="cube-portfolio container margin-top-20">
                <div className="row">
                  <div className="col-md-4">
                    <Member
                      image={jamesLowey}
                      name="James Lowey"
                      title="President"
                      desc="Proven HPC executive with very broad and deep technical knowledge including cluster computing, data-center management, large scale storage systems, systems optimization, and capacity planning."
                      desc2="Adept at building world class teams and environments to help accelerate the pace of discovery. "
                    />
                  </div>                 
                  <div className="col-md-4">
                    <Member
                      image={stuShannon}
                      name="Stu Shannon"
                      title="Chief Product Officer"
                      desc="Accomplished technology innovator driving global and regulatory ready life sciences solutions."
                      desc2="Oversees planning, product, and process development, design transfer, operational infrastructure and supplier development, and the end-to-end portfolio lifecycle management."
                    />
                  </div>
                  <div className="col-md-4">
                    <Member
                      image={apurvaPatel}
                      name="Apurva Patel"
                      title="VP of Product Development"
                      title2="and Innovation"
                      desc="Transformative product leader with a proven track record in conceptualizing and launching software products to improve healthcare delivery to optimize patient outcomes."
                      desc2="Adept at building and managing global teams to deliver results and drive growth for Healthcare and Life Sciences organizations."
                    />
                  </div>
                </div>
                <div className="row">
                 
                  <div className="col-md-4">
                    <Member
                      image={beatrizCampos}
                      name="Beatriz Campos"
                      title="Director of Operations"
                      title2="<br/>"
                      desc="Operations and Administration veteran experienced in helping multi-national organizations build and manage high performing teams."
                      desc2="Proficient in scheduling, budgeting, and team management and committed to streamlining processes and improving workplace productivity."
                    />
                  </div>
                  <div className="col-md-4">
                   
                   </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="serviceBGColor">
            <div className="container content">
              <div className="headline-center headerFontColor">
                <h2>CONTACT US</h2>
                <p></p>
                <p className="text-white">
                  445 N. 5th Street
                  <br />
                  Phoenix, AZ 85004
                  <br />
                  <a className="text-white" href="mailto:info@kaligent.com">
                    info@Kaligent.com
                  </a>
                  <br />
                  602.343.8400
                  <br />
                </p>
              </div>
              <div className="footerCopyRight headline-center">
                <span>
                  {" "}
                  {showCurrentYear()} &copy; All Rights Reserved Kaligent&trade;
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function showCurrentYear() {
    return new Date().getFullYear();
  }
}

export default App;
